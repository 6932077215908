@font-face {
  font-family: HelvNeueOrange;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/HelvNeue55_W1G.woff2") format("woff2"), url("../fonts/HelvNeue55_W1G.woff") format("woff");
}

@font-face {
  font-family: HelvNeueOrange;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/HelvNeue75_W1G.woff2") format("woff2"), url("../fonts/HelvNeue75_W1G.woff") format("woff");
}

@font-face {
  font-family: HelvNeueOrangeArabic;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/HelveticaNeueW20-55Roman.woff2") format("woff2"), url("../fonts/HelveticaNeueW20-55Roman.woff") format("woff");
}

@font-face {
  font-family: HelvNeueOrangeArabic;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/HelveticaNeueW20-75Bold.woff2") format("woff2"), url("../fonts/HelveticaNeueW20-75Bold.woff") format("woff");
}

/*# sourceMappingURL=orangeHelvetica.css.map */